import React, {
  useMemo,
  useState,
} from 'react';

const LazyImage = React.memo(({ src, alt, title }) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => setLoaded(true);

  return (
    <div className="project-item" style={{ opacity: loaded ? 1 : 0.5 }}>
      <img 
        src={src} 
        alt={alt} 
        onLoad={handleImageLoad} 
        loading="lazy" 
      />
      <h1 className="project-title">{title}</h1>
    </div>
  );
});

const Projects = () => {
  const [showAll, setShowAll] = useState(false);

  const projects = useMemo(() => [
    { id: 1, title: 'House 1', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319583/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.08_dcf13b6b_putwtb.jpg' },
    { id: 2, title: 'House 2', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319973/UltraSmart%20Homes/Project%204/WhatsApp_Image_2024-05-25_at_19.25.12_22447204_bde49u.jpg' },
    { id: 3, title: 'House 3', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319573/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.07_b9086291_tnqcvc.jpg' },
    { id: 4, title: 'House 4', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319583/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.08_dcf13b6b_putwtb.jpg' },
    { id: 5, title: 'House 5', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319973/UltraSmart%20Homes/Project%204/WhatsApp_Image_2024-05-25_at_19.25.12_22447204_bde49u.jpg' },
    { id: 6, title: 'House 6', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319573/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.07_b9086291_tnqcvc.jpg' },
    { id: 7, title: 'House 7', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319583/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.08_dcf13b6b_putwtb.jpg' },
    { id: 8, title: 'House 8', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319973/UltraSmart%20Homes/Project%204/WhatsApp_Image_2024-05-25_at_19.25.12_22447204_bde49u.jpg' },
    { id: 9, title: 'House 9', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319573/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.07_b9086291_tnqcvc.jpg' },
    { id: 10, title: 'House 10', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319973/UltraSmart%20Homes/Project%204/WhatsApp_Image_2024-05-25_at_19.25.12_22447204_bde49u.jpg' },
    { id: 11, title: 'House 11', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319573/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.07_b9086291_tnqcvc.jpg' },
    { id: 12, title: 'House 12', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319583/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.08_dcf13b6b_putwtb.jpg' },
    { id: 13, title: 'House 13', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319973/UltraSmart%20Homes/Project%204/WhatsApp_Image_2024-05-25_at_19.25.12_22447204_bde49u.jpg' },
    { id: 14, title: 'House 14', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319573/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.07_b9086291_tnqcvc.jpg' },
    { id: 15, title: 'House 15', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319583/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.08_dcf13b6b_putwtb.jpg' },
    { id: 16, title: 'House 16', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319973/UltraSmart%20Homes/Project%204/WhatsApp_Image_2024-05-25_at_19.25.12_22447204_bde49u.jpg' },
    { id: 17, title: 'House 17', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319573/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.07_b9086291_tnqcvc.jpg' },
    { id: 18, title: 'House 18', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319583/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.08_dcf13b6b_putwtb.jpg' },
    { id: 19, title: 'House 19', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319973/UltraSmart%20Homes/Project%204/WhatsApp_Image_2024-05-25_at_19.25.12_22447204_bde49u.jpg'},
    { id: 20, title: 'House 20', image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319973/UltraSmart%20Homes/Project%204/WhatsApp_Image_2024-05-25_at_19.25.12_22447204_bde49u.jpg'}
  ], []);

  const visibleProjects = useMemo(() => showAll ? projects : projects.slice(0, 8), [showAll, projects]);

  return (
    <div className="portfolio">
      <h1 className='welcome-title'>Welcome to Ultrasmart Constructions</h1>
      <h2 className="portfolio-title">Crafting Homes That Endure</h2>
      <p>Ultrasmart Constructions is dedicated to crafting homes that stand the test 
        of time. We prioritize quality, functionality, and aesthetics to bring your dream 
        home to life. Our commitment to excellence is reflected in every project we undertake.</p>
      <div className="project-list">
        {visibleProjects.map((project) => (
          <LazyImage key={project.id} 
          src={project.image} 
          alt={`Project ${project.id}`} 
          title={project.title} 
          />
        ))}
      </div>
      {!showAll && (
        <div className="container">
        <button className="load-more" onClick={() => setShowAll(true)}>
          See more
        </button>
      </div>
      )}
    </div>
  );
};

export default Projects;
