import React from 'react';

const Deliver = () => {
  return (
    <div className="home-container">
      
      <div className="home-grid">
        <div className="home-item">
          <div className="home-icon">🏡</div>
          <h3 className="home-title">Professional Builders</h3>
          <p>We are professional builders with a vision to turn your dreams into reality</p>
        </div>
        <div className="home-item">
          <div className="home-icon">🛡️</div>
          <h3 className="home-title">We Deliver Quality</h3>
          <p>We deliver quality service, our finishings are unequivocal leaving our jobs spotless</p>
        </div>

        <div className="home-item">
          <div className="home-icon">🏢</div>
          <h3 className="home-title">Always On Time</h3>
          <p>We deliver our project right on time to meet up your expectations</p>
        </div>
        
        <div className="home-item">
          <div className="home-icon">🔧</div>
          <h3 className="home-title">We Are Passionate</h3>
          <p>We are passionate about what we do which has always put us at the forefront ahead of our competitors</p>
        </div>
      </div>
    </div>
  );
};

export default Deliver;
