import React from 'react';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Offers from '../components/Offers';
import ServicesSection from '../components/ServicesSection';

export default function Services() {
  return (
    <div className='service-style'>
      <Navbar/>
      <Offers/>
      <ServicesSection/>
      {/* <VideoGallery/> */}
      <Footer/>
    </div>
  )
}
