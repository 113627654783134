import React from 'react';

const Testimonial = () => {
  return (
    <div className="services-container">
        <h2 className="services-title">Brand Promise</h2>
      
      <div className="services-grid">
        <div className="service-item">
          <div className="service-icon">📜</div>
          <h3 className="service-title">Compliance to Agreement</h3>
        </div>
        <div className="service-item">
          <div className="service-icon"> ⏳</div>
          <h3 className="service-title">Qualilty and Timely Delivery</h3>
        </div>

        <div className="service-item">
          <div className="service-icon">🤝</div>
          <h3 className="service-title">Transparency and Trust</h3>
        </div>
        
        <div className="service-item">
          <div className="service-icon">🏗️</div>
          <h3 className="service-title">Innovative and Modern Designs</h3>
        </div>

        <div className="service-item">
          <div className="service-icon">💬
          {/* <svg width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M20 16v-2a4 4 0 0 0-4-4h-8a4 4 0 0 0-4 4v2" />
            <path d="M16 16a4 4 0 0 1-8 0" />
            <circle cx="12" cy="9" r="9" />
            <path d="M9 10h.01" />
            <path d="M15 10h.01" />
          </svg> */}
          </div>
          <h3 className="service-title">Client Satisfaction and Support</h3>
        </div>
      </div>
    </div>
  );
};

{/* <svg width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <path d="M3 6l9-4 9 4v6a9 9 0 0 1-18 0Z" />
  <path d="M3 6l9 4 9-4" />
  <path d="M9 12V8" />
  <path d="M15 12V8" />
</svg> */}


export default Testimonial;
