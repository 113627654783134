import './index.css';

import React from 'react';

import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// function Main(){
//   return(
//     <h1>I am Ayodeji!</h1>
//   )
// }

// ReactDOM.render(
//   <div>
//     <Main/>
//   </div>

// )
// document.getElementById("root");
