import React from 'react';

export default function Enriching() {
  return (
    <div className="split-section">
      <div className="text-content">
        <h1>Our Vision</h1>
        <h2 className='caveat'>Building Homes, Enriching Lives</h2>
        <p>
        Our team specializes in crafting exceptional custom homes tailored to your vision. From the initial 
        concept to the final touches, we are committed to delivering superior craftsmanship and attention to detail.
        </p>
      </div> 

      <div className="image-sect">
        <img 
          src="https://res.cloudinary.com/dtqrzl86y/image/upload/v1739289891/UltraSmart%20Homes/c837a6_9045f280be72474491617fe62485c878_mv2_qvyh8h.webp" 
          alt="Illustration"
        />
      </div>
    </div>
  )
}
