export default function Enwords() {
  return (
    <div className="wrap">
        <div className="titext">
            <h1>About UltraSmart Constructions</h1>
            <p>Crafting Custom Homes for You!</p>
        </div>
        {/* <div className="imager">
        <img 
          src="https://res.cloudinary.com/dtqrzl86y/image/upload/v1739807600/UltraSmart%20Homes/c837a6_9f6b4d2b2d664fa7bba5458112875242_mv2_jc480b.avif"
          alt="Illustration"
        />
      </div> */}

      <div className="big-section">
      <div className="ensection">
        <div className="entext">
            <h1>Ultrasmart Constructions - Building Dreams into Reality</h1>
            <p>
            Ultrasmart Constructions is dedicated to crafting custom homes that 
            reflect the unique preferences and lifestyle of our clients. Our team is 
            committed to delivering exceptional quality and attention to detail in every 
            project. We take pride in creating spaces that are not only beautiful but also functional, 
            ensuring that every aspect of the home is tailored to perfection.
            </p>
        </div>

        <div className="entext-two">
        <div className='enline'></div>
        <h1>Tailored Relationships</h1>
        <p>
        At Ultrasmart Constructions, we believe in building strong and lasting relationships with our clients. We prioritize open communication and collaboration to ensure that 
        the construction process is seamless and satisfying for our clients. Our goal is to create a partnership that extends beyond the completion of the project.
        </p>
      </div>
    </div>

    <div className="ensection">
        <div className="blank-text"></div>

        <div className="entext-two">
        <div className='enline'></div>
        <h1>Extensive Experience</h1>
        <p>
        With years of experience in the construction industry, Ultrasmart Constructions has 
        successfully completed numerous projects, each adding to our wealth of knowledge and expertise. 
        We have honed our skills to deliver innovative solutions and superior craftsmanship, setting new standards in home construction.
        </p>
      </div>
    </div>

    <div className="ensection">
        <div className="blank-text"></div>

        <div className="entext-two">
        <div className='enline'></div>
        <h1>Unwavering Accountability</h1>
        <p>
        Accountability is at the core of our values at Ultrasmart Constructions. We take full responsibility 
        for our work, ensuring transparency and integrity throughout the construction process. Our clients 
        can trust in our unwavering commitment to delivering on our promises.
        </p>
      </div>
    </div>
      </div>
        
    </div>
  )
}
