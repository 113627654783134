import {
  memo,
  React,
} from 'react';

const About = () => {
const imageLink = 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1730216947/UltraSmart%20Homes/Engineers_kwnqgl.png'
  
return (
      <div className='wrp'>
        <div className="about">
        <div className="bitext">
            <h1>Building Your Vision</h1>
            <p>At Ultrasmart Constructions, We Transform Spaces Into Homes</p>
        </div>

          <div className='about-home'>
            <p className="">
              UltraSmart Construction Limited is a construction company with clear values and result-oriented approach to every project.
              We are building a reliable brand aimed at quality and timely delivery of projects and services to our clients.
            </p>

            <p className='we'>
              We are a progressive real estate company committed to deliverying the best results for our clients. 
              Our team of experienced professionals is dedicated to helping you find your dream home. We offer services cutting across Building 
              and Construction, Real Estate Development, Consulting, Architectural and Structural Designs, 
              and Bill of Quantity.
            </p>

            <p className='we'>
              We also deal in general building materials and supplies and quality paints.
              Possessing an extensive technical know-how with our teams of professionals, we ensure and assure top quality delivery, in time and 
              within budget as of quantity surveyors do their work perfectly.
            </p>
          </div>
        </div>
      </div>
  );
};

export default memo (About);
