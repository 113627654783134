import React from 'react';

import About from '../components/About';
import Deliver from '../components/Deliverables';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Testimonial from '../components/Testimonial';
import VideoGallery from '../components/Videos';

export default function Home() {

  return (
    <div>
      <Navbar />
      <Hero />
      <Deliver/>
      <About />
      {/* <Offering/> */}
      <Testimonial/>
      <VideoGallery/>
      {/* <Projects /> */}
      <Footer />
    </div>
  )
}
